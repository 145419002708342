<template>
  <div>
    <div class="headerBox">
      <div class="logoBox">
        <img class="logo" src="@/assets/logo.png" alt="" />
        <span class="logoTitle">放射影像专业质控中心</span>
      </div>
      <nav class="header-right" v-if="false">
        <ul>
          <li v-for="(item, index) in menuItems" :key="'header-nav' + index" :class="{ active: activeMenu === item }"
            @click="setActiveMenu(item)">
            {{ item }}
          </li>
        </ul>
      </nav>
      <div class="exitBox" v-if="false">
        <span class="accountText">账号：{{ loginName }}</span>
        <!-- <el-button type="text" class="exitBtn resetBtn" @click="resetPassword">重设密码</el-button> -->
        <el-button class="exitBtn" type="text" @click="handleClick">退出</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderNav",
  data() {
    return {
      loginName: localStorage.QCLoginName || "",
      menuItems: ['首页', '机构概况', '会议新闻', '质控调研', '质控指标'],
      activeMenu: '首页' // 默认选中的菜单项
    };
  },
  methods: {
    setActiveMenu(item) {
      this.activeMenu = item; // 设置当前活动菜单项
    },
    handleClick() {
      this.$confirm("您确定要退出当前账号，回到登录界面?", "退出登录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          // 确定
          this.$router.replace("/login");
        })
        .catch(() => {
          // 取消
        });

    },
    resetPassword() {
      this.$router.push('/resetPassword')
    }
  },
};
</script>

<style scoped>
.headerBox {
  height: 80px;
  width: 100%;
  background: url("../../assets/top_bg.png") no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  /* background-color: #fff; */
}

.logoBox {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
}

.logo {
  /* width: 40px; */
  height: 60px;
  margin-right: 10px;
}

.logoTitle {
  font-size: 25px;
  color: #4e8bfe;
  color: #fff;
  letter-spacing: 3px;
}

.exitBox {
  margin-right: 50px;
}

.exitBtn {
  margin-left: 10px;
  color: #fff;
}

.exitBtn:hover {
  color: antiquewhite;
}

.accountText {
  color: #fff;
}

.header-right ul {
  list-style-type: none;
  padding: 0;
}

.header-right li {
  display: inline;
  margin-left: 20px;
  cursor: pointer;
  color: #4e8bfe;
  color: #fff;
  /* 菜单字体颜色 */
}

.header-right li.active {
  color: red;
  /* 活动菜单项颜色变化 */
}
</style>